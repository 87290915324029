<template>

<app-page theme="purple" close-button="Recommendation.Compare">

	<app-title :text="content.title" :is-uppercase="true" is-restricted="true" />

	<app-content :is-light="true">
		
		<div v-html="content.text" />

	</app-content>

</app-page>

</template>

<script>

export default {

	computed: {

		content: function() {

			return this.$_.findWhere(this.$store.getters['data'].more, {
				id: parseInt(this.$route.params.more)
			})

		}

	}

}

</script>

<style scoped>

</style>
